<!--
 * @Description: 账单订单明细
 * @Author: 琢磨先生
 * @Date: 2022-10-27 16:45:04
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-10-27 17:32:54
-->


<template>
  <el-drawer
    title="订单明细"
    v-model="visibleDrawer"
    size="80%"
    custom-class="settle_dtl_drawer"
    :destroy-on-close="true"
    :close-on-click-modal="false"
    :close-on-press-escape="false"
    :before-close="closed"
  >
    <div v-loading="loading" class="data_body" style="height: 100%">
      <el-table :data="tableData.data" border>
        <el-table-column prop="order_no" label="订单号" width="200">
        </el-table-column>
        <el-table-column prop="house_name" label="房源" width="300">
        </el-table-column>
        <el-table-column prop="house_no" label="房号" width="160">
        </el-table-column>
        <el-table-column prop="checkin_date" label="入住时间" width="120">
        </el-table-column>
        <el-table-column prop="checkout_date" label="离店时间" width="120">
        </el-table-column>
        <el-table-column prop="days" label="间夜数" width="100">
        </el-table-column>
        <el-table-column label="结算金额(元)" width="140">
          <template #default="scope">￥{{ scope.row.decimal_amount }}</template>
        </el-table-column>
        <el-table-column label="平台费(元)" width="140">
          <template #default="scope"
            >￥{{ scope.row.decimal_mgr_fee_amount }}</template
          >
        </el-table-column>
      </el-table>
      <el-pagination
        v-if="tableData.counts > 0"
        @size-change="pageSizeChange"
        @current-change="pageCurrentChange"
        :current-page="query.pageIndex"
        :page-sizes="[20, 40, 60, 80, 100]"
        :page-size="query.pageSize"
        layout="total, sizes, prev, pager, next"
        :total="tableData.counts"
      ></el-pagination>
    </div>
  </el-drawer>
</template>

<script>
export default {
  data() {
    return {
      loading: false,
      visibleDrawer: false,
      //数据
      tableData: {
        counts: 0,
      },
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
    };
  },
  props: ["item"],
  emits: ["closed"],
  watch: {
    item: {
      handler() {
        if (this.item) {
          this.visibleDrawer = true;
          this.query.settle_id = this.item.id;
          this.loadData();
        }
      },
      immediate: true,
    },
  },
  methods: {
    /**
     *
     */
    loadData() {
      this.loading = true;
      this.$http
        .post("seller/v1/houseSettle/dtl_record", this.query)
        .then((res) => {
          this.loading = false;
          if (res.code == 0) {
            this.tableData = res.data;
          }
        });
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },
    closed() {
      this.$emit("closed");
      this.visibleDrawer=false;
    },
  },
};
</script>

<style scoped>
.data_body {
  height: 100%;
  display: flex;
  flex-direction: column;
}
.settle_dtl_drawer .el-table {
  flex-grow: 1;
}
</style>