<!--
 * @Description: 平台结算
 * @Author: 琢磨先生
 * @Date: 2022-10-19 22:52:46
 * @LastEditors: 琢磨先生
 * @LastEditTime: 2022-10-27 17:32:21
--> 

<template>
  <!-- <el-card class="box">
        <el-form :model="query" ref="query" :inline="true">
            <el-form-item label="订单号">
                <el-input v-model="query.q" placeholder="请输入订单号"></el-input>
            </el-form-item>
            <el-form-item label="业主名称">
                <el-input v-model="query.consignee" placeholder="收件人姓名"></el-input>
            </el-form-item>
            <el-form-item label="下单日期">
                <el-date-picker v-model="query.date_list" type="daterange" start-placeholder="开始日期"
                    end-placeholder="结束日期" />
            </el-form-item>

            <el-form-item>
                <el-button type="primary" round @click="onSearch" icon="search">查询</el-button>
            </el-form-item>
        </el-form>
  </el-card>-->
  <el-card class="box data" v-loading="loading">
    <el-table :data="tableData.data" border>
      <!-- <el-table-column label="编号" prop="id" width="80"></el-table-column> -->
      <el-table-column label="账期" width="240">
        <template #default="scope"
          >{{ scope.row.start_date }}至{{ scope.row.end_date }}</template
        >
      </el-table-column>
      <el-table-column label="进度" width="140">
        <template #default="scope">
          {{ scope.row.status == 0 ? "账单生成中" : scope.row.status_text }}
        </template>
      </el-table-column>
      <el-table-column label="应收金额" width="140">
        <template #default="scope">￥{{ scope.row.decimal_amount }}</template>
      </el-table-column>
      <el-table-column label="备注" prop="remark" width="240"></el-table-column>
      <!-- <el-table-column
        label="创建时间"
        prop="create_at"
        width="180"
      ></el-table-column> -->
      <el-table-column label="操作" fixed="right">
        <template #default="scope">
          <el-button
            type="primary"
            size="small"
            link
            @click="onOpenDtl(scope.row)"
            >订单明细</el-button
          >
          <el-button
            type="primary"
            link
            @click="onDownExcel(scope.row)"
          ></el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination
      v-if="tableData.counts > 0"
      @size-change="pageSizeChange"
      @current-change="pageCurrentChange"
      :current-page="query.pageIndex"
      :page-sizes="[20, 40, 60, 80, 100]"
      :page-size="query.pageSize"
      layout="total, sizes, prev, pager, next"
      :total="tableData.counts"
    ></el-pagination>
  </el-card>
  <dtl-record
    :item="current"
    @closed="current = null"
  ></dtl-record>
</template>

<script>
import DtlRecord from "./dtl_record.vue";
export default {
  components: {
    DtlRecord,
  },
  data() {
    return {
      loading: false,
      //当前查看的对象
      current: null,
      //数据
      tableData: {
        counts: 0,
      },
      query: {
        pageIndex: 1,
        pageSize: 20,
        order: {
          sort: "",
          fieldName: "",
        },
        q: "",
      },
    };
  },
  created() {
    this.loadData();
  },
  methods: {
    /**
     * 搜索
     */
    onSearch() {
      this.query.pageIndex = 1;
      this.loadData();
    },
    /**
     *
     */
    loadData() {
      this.loading = true;
      this.$http.post("seller/v1/houseSettle", this.query).then((res) => {
        this.loading = false;
        if (res.code == 0) {
          this.tableData = res.data;
        }
      });
    },
    /**
     * 排序
     */
    onSort(e) {
      if (e.prop) {
        this.query.order.sort = e.order;
        this.query.order.fieldName = e.prop;
        this.loadData();
      }
    },
    /**
     * 分页页数更改
     */
    pageSizeChange(val) {
      this.query.pageSize = val;
      this.loadData();
    },
    /**
     * 分页页码更改
     */
    pageCurrentChange(val) {
      this.query.pageIndex = val;
      this.loadData();
    },

    /**
     * 打开账单订单明细
     */
    onOpenDtl(item) {
      this.current = item;
    },

    /**
     * 下载账单
     */
    onDownExcel() {},
  },
};
</script>

<style   scoped>
</style>